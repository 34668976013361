<template>
    <v-container
        ><v-card max-width="600" class="mx-auto">
            <Page-Header :title="$t('users.Profile')" icon="mdi-account">
            </Page-Header>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-badge-account
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        user.userName
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        $t("users.userName")
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-phone
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        user.phoneNumber
                                            ? user.phoneNumber
                                            : "-"
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        $t("phone")
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-email
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        user.email
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        $t("email")
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-account-supervisor-circle
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        user.groupName
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        $t("groups.groupName")
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo">
                                        mdi-two-factor-authentication
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title
                                        :style="
                                            'color:' +
                                                (user.is2FAEnabled
                                                    ? 'green'
                                                    : 'red')
                                        "
                                        >{{
                                            user.is2FAEnabled
                                                ? $t("enabled")
                                                : $t("disabled")
                                        }}</v-list-item-title
                                    >
                                    <v-list-item-subtitle>{{
                                        $t("users.is2FAEnabled")
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn depressed @click="action2fa">
                                        <v-icon
                                            :color="
                                                user.is2FAEnabled
                                                    ? 'red lighten-1'
                                                    : 'green lighten-1'
                                            "
                                        >
                                            {{
                                                user.is2FAEnabled
                                                    ? "mdi-minus-circle"
                                                    : "mdi-check-circle"
                                            }}
                                        </v-icon>
                                        {{
                                            user.is2FAEnabled
                                                ? $t("disable")
                                                : $t("enable")
                                        }}
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <FA_Enable
            ref="enable2faDialog"
            v-on:enable-complete="onEnableComplete"
        ></FA_Enable>
        <FA_Verify
            ref="disable2FaDialog"
            action-type="Disable2Fa"
            v-on:verify-complete="onVerifyComplete"
        ></FA_Verify>
    </v-container>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";
import FA_Enable from "./2FA_Enable.vue";
import FA_Verify from "./2FA_Verify.vue";

export default {
    components: {
        PageHeader,
        FA_Enable,
        FA_Verify
    },
    data() {
        return {
            loading: false,
            user: {}
        };
    },
    created() {
        this.getMyInfo();
    },
    methods: {
        getMyInfo() {
            axios
                .get("Accounts/Me")
                .then(response => {
                    this.user = response.data.data;
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {});
        },
        action2fa() {
            if (this.user.is2FAEnabled) {
                this.$refs.disable2FaDialog.showDialog();
            } else {
                this.$refs.enable2faDialog.showDialog();
            }
        },
        onVerifyComplete(data) {
            this.getMyInfo();
        },
        onEnableComplete(data) {
            this.getMyInfo();
        }
    }
};
</script>
