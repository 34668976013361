<template>
    <v-dialog persistent v-model="dialog" max-width="600">
        <v-card>
            <v-card-title>
                {{ $t("users.verify2faCode") }}
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" icon
                    ><v-icon color="red">mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-5">
                <h3 style="text-align: center;margin-bottom: 25px;">
                    {{ $t("hint_2fa.enter_code2") }}
                </h3>
                <div dir="auto">
                    <v-otp-input
                        :loading="loading"
                        autofocus
                        type="number"
                        :disabled="loading"
                        v-model="twoFaCode"
                        length="6"
                        @finish="onFinish"
                        ref="global_otp_input_ref"
                    ></v-otp-input>
                </div>
                <v-btn
                    ref="verifybtn"
                    :loading="loading"
                    @click="verify2fa"
                    class="mt-5"
                    :disabled="!twoFaCode || twoFaCode.length < 6"
                    color="primary"
                    >{{ $t("users.verify") }}</v-btn
                >
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";

export default {
    components: {},
    props: {
        actionType: String
    },
    data() {
        return {
            loading: false,
            dialog: false,
            twoFaCode: null
        };
    },
    created() {},
    watch: {
        dialog: function(newVal, oldVal) {
            this.twoFaCode = null;
        }
    },
    methods: {
        showDialog() {
            this.dialog = true;
            this.$nextTick(() =>
                setTimeout(() => {
                    this.$refs.global_otp_input_ref.focus();
                }, 250)
            );
        },
        verify2fa() {
            this.loading = true;
            axios
                .post(`Accounts/${this.actionType}?code=${this.twoFaCode}`)
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        if (this.actionType == "Verify2Fa") {
                            this.$store.dispatch("login", response.data.data);
                        }

                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.verifyComplete();
                        this.dialog = false;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(error => {
                    console.dir(error);
                    if (
                        error.response.status == 401 &&
                        this.actionType == "Verify2Fa"
                    ) {
                        this.$router.push("/auth");
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onFinish() {
            this.verify2fa()
        },
        verifyComplete() {
            this.$emit("verify-complete");
        }
    }
};
</script>
