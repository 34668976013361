<template>
    <v-dialog persistent v-model="dialog" width="650">
        <v-card>
            <v-card-title class="">
                {{ $t("users.enable2Fa") }}
                <v-spacer></v-spacer>
                <v-btn @click="dialog = false" icon
                    ><v-icon color="red">mdi-close</v-icon></v-btn
                >
            </v-card-title>

            <v-stepper v-model="stepItem"
                ><v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card-text class="pa-0" style="min-height: 500px;">
                            <v-row>
                                <v-col>
                                    <h3>
                                        {{ $t("hint_2fa.download_app") }}
                                    </h3>
                                    <br />
                                    <div>
                                        {{
                                            $t("hint_2fa.android_instructions")
                                        }}
                                        <a
                                            target="_blank"
                                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                                            >Google Authenticator</a
                                        >
                                        {{
                                            $t(
                                                "hint_2fa.android_instructions_install"
                                            )
                                        }}
                                    </div>
                                    <div>
                                        {{ $t("hint_2fa.ios_instructions") }}
                                        <a
                                            target="_blank"
                                            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                            >Google Authenticator</a
                                        >
                                        {{
                                            $t(
                                                "hint_2fa.ios_instructions_install"
                                            )
                                        }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                            <!-- Step 2: Open the App -->
                            <v-row>
                                <v-col>
                                    <h3>
                                        {{ $t("hint_2fa.open_app") }}
                                    </h3>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                            <!-- Step 3: Add a New Account -->
                            <v-row>
                                <v-col>
                                    <h3>
                                        {{ $t("hint_2fa.add_account") }}
                                    </h3>
                                    <p>
                                        {{
                                            $t(
                                                "hint_2fa.add_account_instructions"
                                            )
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                            <!-- Step 4: Scan QR Code -->
                            <v-row>
                                <v-col>
                                    <h3>
                                        {{ $t("hint_2fa.scan_qr") }}
                                    </h3>
                                    <p>
                                        {{
                                            $t("hint_2fa.scan_qr_instructions")
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                            <!-- Step 5: Enter Code in Service -->
                            <v-row>
                                <v-col>
                                    <h3>
                                        {{ $t("hint_2fa.enter_code") }}
                                    </h3>
                                    <p>
                                        {{
                                            $t(
                                                "hint_2fa.enter_code_instructions"
                                            )
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                            <!-- Step 6: Complete Activation -->
                            <v-row>
                                <v-col>
                                    <h3>
                                        {{ $t("hint_2fa.complete_activation") }}
                                    </h3>
                                    <p>
                                        {{
                                            $t(
                                                "hint_2fa.complete_activation_instructions"
                                            )
                                        }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="stepItem = 2">
                                {{ $t("next") }}
                            </v-btn>
                        </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-card-text class="pa-0" style="min-height: 500px;">
                            <v-row justify="center">
                                <v-col align="center">
                                    <h3>
                                        {{ $t("hint_2fa.scan_qr2") }}
                                    </h3>

                                    <v-img
                                        class="mt-4"
                                        v-if="dialog"
                                        max-height="450"
                                        max-width="450"
                                        :loading="loading"
                                        :src="qrCode"
                                    ></v-img>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn text @click="stepItem = 1">
                                {{ $t("previous") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="stepItem = 3">
                                {{ $t("next") }}
                            </v-btn>
                        </v-card-actions>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <v-card-text class="pa-0" style="min-height: 500px;">
                            <v-row>
                                <v-col>
                                    <h3
                                        style="text-align: center;margin-bottom: 25px;"
                                    >
                                        {{ $t("hint_2fa.enter_code2") }}
                                    </h3>
                                    <div dir="auto">
                                        <v-otp-input
                                            :loading="loading"
                                            autofocus
                                            type="number"
                                            :disabled="loading"
                                            v-model="twoFaCode"
                                            length="6"
                                            @finish="onFinish"
                                            ref="otp_input_ref"
                                        ></v-otp-input>
                                    </div>
                                    <v-btn
                                        :loading="loading"
                                        @click="enable2fa"
                                        class="mt-5"
                                        :disabled="
                                            !twoFaCode || twoFaCode.length < 6
                                        "
                                        color="primary"
                                        >{{ $t("enable") }}</v-btn
                                    >
                                </v-col></v-row
                            >
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn text @click="stepItem = 2">
                                {{ $t("previous") }}
                            </v-btn></v-card-actions
                        >
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";

export default {
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            dialog: false,
            qrCode: null,
            stepItem: 1,
            twoFaCode: null,
            timer: 30
        };
    },
    created() {
        this.getMyInfo();
        
    },
    watch: {
        dialog: function(newVal, oldVal) {
            if (this.dialog) {
                this.getQrCode();
            } else {
                this.qrCode = null;
                this.stepItem = 1;
                this.twoFaCode = null;
            }
        },
        stepItem: function(newVal, oldVal) {
            if (newVal == 3) {
                this.$nextTick(() =>
                    setTimeout(() => {
                        this.$refs.otp_input_ref.focus();
                    }, 250)
                );
            }
        }
    },
    methods: {
       
        showDialog() {
            this.dialog = true;
        },
        getQrCode() {
            this.loading = true;
            axios
                .post("Accounts/Get2FAqrCodeAsBase64")
                .then(response => {
                    this.qrCode = response.data;
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        enable2fa() {
            this.loading = true;
            axios
                .post("Accounts/Enable2Fa?code=" + this.twoFaCode)
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.enableComplete();
                        this.dialog=false;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                  
                })
                .catch(error => {
                    console.log(error);
                    this.$toast.error(this.$t("error." + error.message));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    
        onFinish() {
            this.enable2fa();
        },
        enableComplete(){
            this.$emit('enable-complete');
        }
    }
};
</script>
